import React from 'react';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout';
import Link from '../components/Link';
import { getLocale } from '../helpers/i118Helper';

const TagRoute = ({ data, pageContext, location }) => {
  const { t } = useTranslation();
  const posts = data.allMarkdownRemark.edges;
  const tag = pageContext.tag;
  const title = data.site.siteMetadata.title;
  const locale = getLocale();
  const filterd = filter(posts, p => {
    return locale === pageContext.lang
      ? includes(p.node.fields.slug, '/en/')
      : !includes(p.node.fields.slug, '/en/');
  });

  return (
    <Layout lang={pageContext.lang} indexed={false} location={location}>
      <section className="section">
        <Helmet title={`${tag} | ${title}`} />
        <div className="container content">
          <div className="columns">
            <div
              className="column is-10 is-offset-1"
              style={{ marginBottom: '6rem' }}
            >
              <h3 className="title is-size-4 is-bold-light">
                {t('tags.keyWithCount', { count: filterd.length, tag })}
              </h3>
              <ul className="taglist">
                {map(filterd, post => (
                  <li key={post.node.fields.slug}>
                    <Link to={post.node.fields.slug}>
                      <h2 className="is-size-2">
                        {post.node.frontmatter.title}
                      </h2>
                    </Link>
                  </li>
                ))}
              </ul>
              <p>
                <Link to="/tags">{t('tags.browse_tags')}</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

TagRoute.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.instanceOf(Object),
    site: PropTypes.instanceOf(Object),
  }),
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
